import Paragraph from "@material-tailwind/react/Paragraph";
import Input from "@material-tailwind/react/Input";
import Textarea from "@material-tailwind/react/Textarea";
import Button from "@material-tailwind/react/Button";
import axios from "axios";
import { FiArrowUpRight } from "react-icons/fi";

import "../../assets/styles/custom.css";
import { useState } from "react";
import { Label } from "@material-tailwind/react";

export default function Form() {
  const [sendData, setSendData] = useState({
    enq_name: "",
    enq_phone: "",
    enq_email: "",
    enq_message: "",
  });
  const [notification, setNotification] = useState(false);
  const [error, setError] = useState(false);

  const handleChange = (e) => {
    setSendData({ ...sendData, [e.target.name]: e.target.value });
  };

  function sendEmail(e, v) {
    e.preventDefault();

    axios
      .post(
        "https://mail-sender.vingb.com/send/2beb7373-4441-4e32-8a69-724d8305b86e",
        sendData
      )
      .then((response) => {
        setNotification(true);
        setTimeout(() => {
          setNotification(false);
        }, 5000);
      })
      .catch((err) => {
        setError(true);
        setTimeout(() => {
          setError(false);
        }, 3000);
      });
  }

  return (
    <>
      <div className="flex flex-wrap justify-center mt-24 bg-white">
        <div className="w-full lg:w-8/12 px-4">
          <div className="relative flex flex-col min-w-0 break-words w-full mb-6">
            <div className="flex-auto p-5 lg:p-10">
              <div className="w-full text-center">
                <p
                  color="gray"
                  className="font-mont text-3xl font-bold leading-normal mt-0 mb-2"
                >
                  We’d love to hear from you
                </p>
                <Paragraph color="blueGray">
                  Connect us through this form and we will get back to you
                  within 24 hours.
                </Paragraph>
              </div>
              <form onSubmit={sendEmail}>
                <div className="flex gap-8 mt-16 mb-12 ">
                  <Input
                    type="number"
                    min={0}
                    minLength={10}
                    maxLength={10}
                    placeholder="Phone"
                    color="lightBlue"
                    name="enq_phone"
                    value={sendData.enq_phone}
                    onChange={handleChange}
                    outline={true}
                    required={true}

                  />
                  <Input
                    type="email"
                    placeholder="Email"
                    color="lightBlue"
                    name="enq_email"
                    onChange={handleChange}
                    value={sendData.enq_email}
                    outline={true}
                    required={true}

                  />
                </div>

                <Textarea
                  color="lightBlue"
                  placeholder="Brief your requirement"
                  name="enq_message"
                  onChange={handleChange}
                  outline={true}
                />

                <div className="textarea-height"></div>

                <div className="flex justify-center mt-10 flex-column max-w-xs m-auto">
                  <Button
                    className="custom_btn bg-black"
                    color="black"
                    ripple="light"
                    type="submit"
                  >
                    Send Message
                    <FiArrowUpRight size={20} />
                  </Button>
                  {notification && (
                    <Label className="mx-4 bg-light text-green-500 pt-4">
                      Successfully Submitted
                    </Label>
                  )}
                  {error && (
                    <Label className="bg-light text-red-500 pt-4 ml-auto">
                      Somthing Wrong, Try Again
                    </Label>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
