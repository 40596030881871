import "../../assets/styles/custom.css";
import Form from "./Form";

export default function ContactSection() {
  return (
    <div id="contact">
      <Form />
    </div>
  );
}
