import Paragraph from "@material-tailwind/react/Paragraph";

export default function ContactCard({ icon, title, children }) {
  return (
    <div className="w-full lg:w-3/12 px-4 text-center">
      <div className=" p-10 shadow-none rounded-full bg-white inline-flex items-center justify-center mb-6">
        {icon}
      </div>
      <p color="gray" className="font-mont text-base leading-normal mt-0 mb-2">
        {title}
      </p>
      <Paragraph color="blueGray">{children}</Paragraph>
    </div>
  );
}
