import OurClients from "components/LandingPage/OurClients";
import OurServices from "components/OurServices/OurServices";
import WhyChooseUs from "components/WhyChooseUs/WhyChooseUs";
import React, { lazy, Suspense, useState, useEffect } from "react";

const DefaultNavbar = lazy(() => import("../components/DefaultNavbar"));
const AboutSection = lazy(() => import("../components/LandingPage/FirstSection/FirstSection"));
const Callback = lazy(() => import("../components/common/callBackBtn"));
const Products = lazy(() => import("../components/LandingPage/Products"));
const AboutOsperb = lazy(() => import("../components/LandingPage/AboutOsperb"));
const ContactSection = lazy(() =>
  import("../components/ContactUs/ContactSection")
);
const WhatWeDo = lazy(() => import("../components/LandingPage/whatwedo"));
const Technology = lazy(() => import("../components/LandingPage/Technology"));
const MetaData = lazy(() => import("../MetaData"));

export default function Landing() {
  const PreLoad = (
    <div className="h-screen w-100 flex items-center justify-center bg-black">
      {/* <h3 className="text-osperb-blue">Osperb</h3> */}
    </div>
  );
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    setIsMobile(window.innerWidth <= 768);
  }, []);
  return (
    <>
      <MetaData
        title={"Kerala based software development company"}
        content={
          "At osperb, our team has equipped with a visionary agenda on making human life and activities easier."
        }
        description={"As a software development company, we build native mobile apps for iOS & Android, web applications, ERP softwares and other IT solutions for business needs."}
        keyword={
          "bset software company,top IT company, website, mobile app, software, website development malappuram, factory management system development, mahal management system development,erp development, institute management system, development, software agency, software development kerala, indian software company"
        }
      />
      <Suspense fallback={PreLoad}>
        <DefaultNavbar />
      </Suspense>

      <main>
        <Suspense fallback={PreLoad}>
          <AboutSection />
        </Suspense>
        <Suspense fallback={PreLoad}>
          <AboutOsperb />
        </Suspense>
        <Suspense fallback={PreLoad}>
          <Technology />
        </Suspense>
        <Suspense fallback={PreLoad}>
          <Callback />
        </Suspense>
        <Suspense fallback={PreLoad}>
          <WhatWeDo />
        </Suspense>
        <Suspense fallback={PreLoad}>
          {isMobile ? <Products home />
            : <Products />}
        </Suspense>
        <Suspense fallback={PreLoad}>
          <OurServices />
        </Suspense>
        <Suspense fallback={PreLoad}>
          <WhyChooseUs />
        </Suspense>
        <Suspense fallback={PreLoad}>
          <OurClients />
        </Suspense>
        <Suspense fallback={PreLoad}>
          <ContactSection />
        </Suspense>
      </main>
    </>
  );
}
