import React, { useState } from "react";
import Modal from "@material-tailwind/react/Modal";
import ModalBody from "@material-tailwind/react/ModalBody";
import ModalFooter from "@material-tailwind/react/ModalFooter";
import Button from "@material-tailwind/react/Button";
import { GrClose } from "react-icons/gr";
import {
  ClosingAlert,
  Image,
  Input,
  Paragraph,
  Textarea,
} from "@material-tailwind/react";
import { FiArrowUpRight } from "react-icons/fi";
import axios from "axios";

export default function CallBackModal({
  showModal,
  setShowModal,
  isApp,
  isGallery,
  image,
}) {
  const [sendData, setSendData] = useState({
    enq_name: "",
    enq_phone: "",
    enq_email: "",
    enq_message: "",
  });

  const [notification, setNotification] = useState({
    status: false,
    class: "",
    message: "",
  });

  const handleChange = (e) => {
    setSendData({ ...sendData, [e.target.name]: e.target.value });
  };

  function sendEmail(e, v) {
    e.preventDefault();

    axios
      .post(
        "https://mail-sender.vingb.com/send/2beb7373-4441-4e32-8a69-724d8305b86e",
        sendData
      )
      .then((response) => {
        setNotification({
          status: true,
          class: "lightGreen",
          message: "Successfully Submitted",
        });
        setTimeout(() => {
          setNotification({
            status: false,
          });
        }, 3000);
        setShowModal(false);
      })
      .catch((err) => {
        setNotification({
          status: true,
          class: "red",
          message: "Somthing Wrong",
        });
        setTimeout(() => {
          setNotification({
            status: false,
          });
        }, 2000);
        setShowModal(false);
      });
  }

  return (
    <>
      <div className="max-w-sm ml-auto p-4 absolute right-0 top-20">
        {notification?.status === true && (
          <ClosingAlert color={notification.class}>
            {notification.message}
          </ClosingAlert>
        )}
      </div>
      <Modal
        size={isGallery ? "large" : "regular"}
        active={showModal}
        toggler={() => setShowModal(false)}
      >
        {/* <ModalHeader toggler={() => setShowModal(false)}> </ModalHeader> */}
        <GrClose
          onClick={() => setShowModal(false)}
          className="cursor-pointer "
        />
        {isGallery ? (
          <>
            {/* <div className="h-3/4-screen"> */}
            <Image
              src={image}
              title="projects by osperb innovations malappuram kerala"
              alt="projects by osperb innovations malappuram kerala"
            />
            {/* </div> */}
          </>
        ) : (
          <form onSubmit={sendEmail}>
            <ModalBody>
              <div className="flex-auto p-5 lg:p-10">
                <div className="w-full text-center">
                  <p
                    color="gray"
                    className="font-mont text-2xl font-bold leading-normal mt-0 mb-2"
                  >
                    We’d love to hear from you
                  </p>
                  <Paragraph color="blueGray">
                    Connect us through this form and we will get back to you
                    within 24 hours.
                  </Paragraph>
                </div>
                <div className="flex gap-8 mt-16 mb-12">
                  <Input
                    type="number"
                    min={0}
                    placeholder="Phone"
                    color="lightBlue"
                    name="enq_phone"
                    value={sendData.enq_phone}
                    onChange={handleChange}
                    outline={true}
                    required={true}
                  />
                  <Input
                    type="email"
                    placeholder="Email"
                    color="lightBlue"
                    name="enq_email"
                    onChange={handleChange}
                    value={sendData.enq_email}
                    outline={true}
                    required={true}
                  />
                </div>
                <div className="textarea-height">
                  <Textarea
                    color="lightBlue"
                    placeholder="Brief your requirement"
                    name="enq_message"
                    onChange={handleChange}
                    outline={true}
                  />
                </div>

                {/* <div className="flex justify-center mt-10">
                <Button className="custom_btn" color="lightBlue" ripple="light">
                  Send Message
                  <FiArrowUpRight size={20} />
                </Button>
              </div> */}
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                color="red"
                buttonType="link"
                onClick={(e) => setShowModal(false)}
                ripple="dark"
                type="button"
              >
                {isApp ? "Not Now" : "Close"}
              </Button>

              <Button
                type="submit"
                className="custom_btn bg-black"
                color="black"
                ripple="light"
                // onClick={handleFinalSubmit}
              >
                Send Message
                {notification?.status === true ? (
                  <>
                    <div class="spinner-border text-primary"></div>
                  </>
                ) : (
                  <FiArrowUpRight size={20} />
                )}
              </Button>
            </ModalFooter>
          </form>
        )}
      </Modal>
    </>
  );
}
