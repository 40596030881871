import { RiTeamLine } from "react-icons/ri";
import { TbHeartHandshake } from "react-icons/tb";
import { AiOutlineKey } from "react-icons/ai";
import { FiAward } from "react-icons/fi";
import ContactCard from "./ContactCard";
import Title from "components/common/Title";

const WhyChooseUs = () => {
  return (
    <>
      <section className="pb-20 pt-20 lg:pt-0 relative block bg-gray-100">
        <div className="container max-w-7xl mx-auto px-4 lg:pt-24">
          <Title heading="Why choose us!">
            the best decision towards your vision.
          </Title>

          <div className="flex flex-wrap -mt-12 justify-center">
            <ContactCard icon={<TbHeartHandshake size={30} />} title="Integrity">
              Building trust with customers,
              communities & one another by
              doing what is right, keeping our
              promises, being a good citizen,
              complying with regulations and
              laws, & honoring rules of
              engagement.
            </ContactCard>
            <ContactCard icon={<RiTeamLine size={30} />} title="Team Work">
              Working across organization &
              cultural boundaries to achieve
              extraordinary performance &
              delivery personal service to
              customers.
            </ContactCard>

            <ContactCard icon={<AiOutlineKey size={30} />} title="Personal Ownership">
              Taking personal responsibilty for
              the outcome by anticipating
              needs, being resourceful and
              following through until the jobs
              in done.
            </ContactCard>
            <ContactCard icon={<FiAward size={30} />} title="Excellence">
              Building a culture based on
              excellence in though & in
              execution to better serve
              customers.
            </ContactCard>
          </div>

        </div>
      </section>

    </>
  )
}

export default WhyChooseUs;