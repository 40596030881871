import { RiTeamLine } from "react-icons/ri";
import { TbHeartHandshake } from "react-icons/tb";
import { AiOutlineKey } from "react-icons/ai";
import { FiAward } from "react-icons/fi";
import ContactCard from "./ContactCard";
import Title from "components/common/Title";
import { Fleet, Courier, Delivery, Dispatch, Inbound, ThirdParty, Warehouse } from "assets/img";
import { Image } from "@material-tailwind/react";

const OurServices = () => {
  return (
    <>
      <section className="pb-20 pt-20 lg:pt-0 relative block bg-gray-100">
        <div className="container max-w-7xl mx-auto px-4 lg:pt-24">
          <Title heading="Our Services">
          </Title>
          <div className="flex flex-wrap -mt-12 justify-center">
            <ContactCard icon={<Image src={Inbound} style={{ height: 150, width: 150, objectFit: 'contain' }} />} title="Inbound Logistics" />
            <ContactCard icon={<Image src={Fleet} style={{ height: 150, width: 150, objectFit: 'contain' }} />} title="Contract Fleet" />
            <ContactCard icon={<Image src={Warehouse} style={{ height: 150, width: 150, objectFit: 'contain' }} />} title="Warehousing Solutions" />
            <ContactCard icon={<Image src={Delivery} style={{ height: 150, width: 150, objectFit: 'contain' }} />} title="Outbound Logistics" />
            <ContactCard icon={<Image src={ThirdParty} style={{ height: 150, width: 150, objectFit: 'contain' }} />} title="3PL Logistics" />
            <ContactCard icon={<Image src={Dispatch} style={{ height: 150, width: 150, objectFit: 'contain' }} />} title="Dispatch Services" />
            <ContactCard icon={<Image src={Courier} style={{ height: 150, width: 150, objectFit: 'contain' }} />} title="Courier Services" />
          </div>

        </div>
      </section>

    </>
  )
}

export default OurServices;