import {
  Ecolums_h,
  Indtech_h,
  loyal_h,
  Suppriya_h,
  Loha_h,
  Layamr_h,
  IndtechApp_h,
  LohaApp_h,
  Ipasbook_h,
  IpasOman_h,
  Ecolums_web1,
  Loha_web2,
  Layamr_web2,
  Loyal_web1,
  Supriya_web1,
  Loha_fms1,
  Loha_fms2,
  Indtech_fms1,
  Indtech_fms2,
  Indtech_fms3,
  IpasOman_web1,
  Ipas_web1,
  Ipas_web2,
  Layamr_web1,
  Loha_web1,
  Zeston_app1,
  Zeston_web_dashboard1,
  Zeston_web_dashboard2,
  Zeston_web_dashboard3,
  Zeston_web_dashboard4,
  Zeston_web1,
  zeston_app,
  Layamr_web3,
  Layamr_web4,
  Loha_fms_h,
  Loha_fms3,
  DockerVan,
  HiasBus,
  HiasRefrBus,
  Dyna,
  DynaWithRefr,
} from "./img";

export const colors = {
  navColor: "#333",
  white: "#fff",
  black: "#000",
  navBrandColor: "#1881bb",
  osperb: "#1881bb",
};

// 1881bb
//first 3 object for homepage
//use "All", "Web","Mobile", "ERP" for fiilter option you can add more in TouchBar.js file

export const Products = [
  {
    id: "1",
    homeImage: DockerVan,
    TouchBarImg: DockerVan,
    images: [DockerVan, DockerVan],
    filter: ["Mobile", "Web"],
    clientName: "Docker Van",
  },
  {
    id: "2",
    homeImage: HiasBus,
    TouchBarImg: HiasBus,
    images: [HiasBus, HiasBus],
    filter: ["Mobile", "Web"],
    clientName: "Hiace Bus",
  },
  {
    id: "3",
    homeImage: HiasRefrBus,
    TouchBarImg: HiasRefrBus,
    images: [HiasRefrBus, HiasRefrBus],
    filter: ["Mobile", "Web"],
    clientName: "Hiace Bus with Refrigerator",
  },
  {
    id: "4",
    homeImage: Dyna,
    TouchBarImg: Dyna,
    images: [Dyna, Dyna],
    filter: ["Mobile", "Web"],
    clientName: "Dyna Truck",
  },
  {
    id: "5",
    homeImage: DynaWithRefr,
    TouchBarImg: DynaWithRefr,
    images: [DynaWithRefr, DynaWithRefr],
    filter: ["Mobile", "Web"],
    clientName: "Dyna Truck with Refrigerator",
  },
];
