import React, { lazy, Suspense } from "react";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ContactSection = lazy(() =>
  import("../components/ContactUs/ContactSection")
);
const DefaultNavbar = lazy(() => import("../components/DefaultNavbar"));
const Work = lazy(() => import("../components/LandingPage/Products"));
const Header = lazy(() => import("../components/Portfolio/Header"));
const TouchBar = lazy(() => import("../components/Portfolio/TouchBar"));
const MetaData = lazy(() => import("../MetaData"));

function Portfolio() {
  const PreLoad = (
    <div className="h-screen w-100 flex items-center justify-center bg-black">
      {/* <h3 className="text-osperb-blue">Osperb</h3> */}
    </div>
  );
  return (
    <>
      <MetaData
        title={"Portfolio"}
        content={
          "At osperb, our team has equipped with a visionary agenda on making human life and activities easier."
        }
        keyword={
          "website, app, software, website malappuram, internship, UAE, OMAN, CANADA, ERP, CRM, CMS, FMS, factory management system, mahal management system, institute management system, disgning, branding, digital markerting social media"
        }
      />
      <Suspense fallback={PreLoad}>
        <DefaultNavbar />
      </Suspense>
      <Suspense fallback={PreLoad}>
        <Header />
      </Suspense>
      <Suspense fallback={PreLoad}>
        <Work isFolio />
      </Suspense>
      <Suspense fallback={PreLoad}>
        <TouchBar />
      </Suspense>
      <Suspense fallback={PreLoad}>
        <ContactSection />
      </Suspense>
    </>
  );
}

export default Portfolio;
