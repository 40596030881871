import { Switch, Route, Redirect } from "react-router-dom";
import { useEffect, useState, Suspense } from "react";

// Font Awesome Style Sheet
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/styles/index.css";
import "assets/styles/singleWork.css";
import "assets/styles/slider.css";

import Landing from "./pages/LandingPage";
import DefaultFooter from "./components/DefaultFooter";
import SingleWork from "./pages/SingleWork";
import Portfolio from "./pages/Portfolio";
import NotFound from "./pages/404";

import ScrollToTop from "./components/common/ScrollTop";
import CallBackModal from "./components/common/CallBackModal";

function App() {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      // setShowModal(true);
    }, 5000);
  }, []);

  const PreLoad = (
    <div className="h-screen w-100 flex items-center justify-center ">
      <h3 className="text-osperb-blue">WHL</h3>
    </div>
  );

  return (
    <Suspense fallback={PreLoad}>
      <CallBackModal showModal={showModal} setShowModal={setShowModal} isApp />
      <ScrollToTop />

      <Switch>
        <Route exact path="/" component={Landing} />
        <Route path="/work/:id" component={SingleWork} />

        <Route path="/portfolio" component={Portfolio} />

        <Route path="/404" exact component={NotFound} />
        <Redirect from="*" to="/404" />
      </Switch>
      <DefaultFooter />
    </Suspense>
  );
}

export default App;
