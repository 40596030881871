import Title from "components/common/Title";
import React, { useState, useEffect } from "react";
import { Aldrees, Alyam, GFS, Lebara, Mrsool, Noon, Roadmoments, Saee, Sakr, Tadweem, Toyou, Tracking } from "../../assets/img";

export default function OurClients() {
  const [more, setMore] = useState()
  const [clients] = useState([
    Noon,
    Saee,
    Sakr,
    Mrsool,
    Aldrees,
    Alyam,
    Toyou,
    Lebara,
    Tadweem,
    GFS,
    Roadmoments,
    Tracking
  ])
  useEffect(() => {
    if (window.innerWidth >= 768) {
      setMore(true)
    }
  }, []);
  return (
    <section className=" md-px-auto">
      <div className="container  mx-auto px-1">
        <Title heading="Our Esteemed Clients"></Title>
      </div>
      <div className="flex flex-wrap -mt-12 justify-center align-center">
        {
          clients.map((c) => {
            return (<div class='p-10'>
              <img style={{ maxHeight: '150px', width: '200px', objectFit: 'contain' }} src={c} />
            </div>)
          })
        }
      </div>
      <div>
      </div>
    </section>
  );
}
