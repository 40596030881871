import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Navbar from "@material-tailwind/react/Navbar";
import NavbarContainer from "@material-tailwind/react/NavbarContainer";
import NavbarWrapper from "@material-tailwind/react/NavbarWrapper";
import NavbarBrand from "@material-tailwind/react/NavbarBrand";
import NavbarCollapse from "@material-tailwind/react/NavbarCollapse";
import Nav from "@material-tailwind/react/Nav";
import Button from "@material-tailwind/react/Button";
import { FaHome, FaPhoneVolume } from "react-icons/fa";
import { HashLink } from "react-router-hash-link";
import { NavItem } from "@material-tailwind/react";

//images & colors
import CallBackModal from "../components/common/CallBackModal";
import { colors } from "../assets/config";
import { MdHandyman } from "react-icons/md";
import { BsBookmarksFill } from "react-icons/bs";
import { RiContactsFill } from "react-icons/ri";

export default function DefaultNavbar() {
  // const [openNavbar, setOpenNavbar] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const path = window.location.pathname;

  // scroll direction tracking start
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [bodyOffset, setBodyOffset] = useState(
    document.body.getBoundingClientRect()
  );
  const [scrollDirection, setScrollDirection] = useState();
  const listener = (e) => {
    setBodyOffset(document.body.getBoundingClientRect());
    setScrollDirection(lastScrollTop > -bodyOffset.top ? "down" : "up");
    setLastScrollTop(-bodyOffset.top);
  };
  useEffect(() => {
    window.addEventListener("scroll", listener);
    return () => {
      window.removeEventListener("scroll", listener);
    };
  });

  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    setIsMobile(window.innerWidth <= 768);
  }, []);

  return (
    <>
      <CallBackModal showModal={showModal} setShowModal={setShowModal} />
      <div className="">

        <div className="absolute w-full z-20 blend ">
          <Navbar
            color="whitesmoke"
            navbar
            className={`navBarUl ${scrollDirection === "up" ? "ulHidden" : "ulShow"
              } custom_nav height-80 w-100 fixed pt-5`}

          >
            <NavbarContainer className="h-full">
              <NavbarWrapper className="h-full">
                <Link to="/" className="h-full nav-span">
                  <NavbarBrand color={"gray"} className="h-full">
                    <p
                      style={{
                        color: colors.white,
                        background: "black",
                        textTransform: "none",
                      }}
                      className="font-poppins text-3xl"
                    >
                      WHL
                    </p>
                  </NavbarBrand>
                </Link>

                <div className="flex flex-wrap items-center justify-between">
                  <span className="mx-5 sm:m-0" onClick={(e) => setShowModal(true)}>
                    <Button
                      color="blue"
                      className="bg-light ml-4 d-none-bigger sm:p-0"
                      style={{
                        color: colors.white,
                        background: "black",
                        boxShadow: "none",
                      }}
                      ripple="dark"
                    >
                      <a
                        href="tel:+966509460554"
                        title="Give me a call"
                        className="queries"
                      >
                        <div className="sm:w-none qu_icon">
                          <FaPhoneVolume />
                        </div>
                        <p className=" font-popins text-sm ">+966 50 946 0554</p>
                      </a>
                    </Button>
                  </span>
                  {/* <NavbarToggler
                    onClick={() => setOpenNavbar(!openNavbar)}
                    color="white"
                    ripple={"light"}
                  /> */}
                </div>
              </NavbarWrapper>



              {!isMobile && <NavbarCollapse
                className={`h-full `}
                open={true}
              >
                <Nav className="m-0 sm:bg-glass w-fit">
                  <div className="sm:d-none flex flex-col z-50 lg:flex-row lg:items-center text-black md:items-end">
                    <NavItem
                      ripple="light"
                      style={{
                        color: colors.white,
                        cursor: "pointer",
                        marginLeft: "5px",
                      }}
                    >
                      <Link
                        to="/"
                        className={`underline-hover ${path === "/" && "full"} font-popins`}
                      >
                        Home
                      </Link>
                    </NavItem>
                    {/* <NavItem
                      ripple="light"
                      style={{
                        color: colors.white,
                        cursor: "pointer",
                        marginLeft: "5px",
                      }}
                    >
                      {/* <Link
                        to="/portfolio"
                        className={`underline-hover ${path === "/portfolio" && "full"
                          }font-popins `}
                      >
                        Portfolio
                      </Link> */}
                    {/* </NavItem> */} */}

                    <NavItem
                      ripple="light"
                      style={{
                        color: colors.white,
                        cursor: "pointer",
                        marginLeft: "5px",
                      }}
                    >
                      <HashLink
                        smooth={true}
                        to={`${path}#contact`}
                        className={`underline-hover ${path === "/#contact" && "full"
                          }font-popins `}
                      >
                        Contact Us
                      </HashLink>
                    </NavItem>
                    <span
                      onClick={(e) => setShowModal(true)}
                      className="d-none-smaller mx-5"
                    >
                      <Button
                        className="bg-light ml-4 "
                        style={{
                          color: colors.white,
                          background: "black",
                          boxShadow: "none",
                        }}
                        ripple="dark"
                      >
                        <a
                          href="tel:+966509460554"
                          title="Give me a call"
                          className="queries"
                        >
                          <div className="qu_icon">
                            <FaPhoneVolume />
                          </div>
                          <p className="font-popins text-sm ">+966 50 946 0554</p>
                        </a>
                      </Button>
                    </span>
                  </div>
                </Nav>
              </NavbarCollapse>}
            </NavbarContainer>
          </Navbar>
        </div>
        {isMobile && <>
          <div className="fixed w-full bottom-0 z-50 p-0" >
            <div className="flex w-full justify-evenly bg-whitesmoke p-2"
              style={{ boxShadow: " 0px 1px 4px 3px rgb(0 0 0 / 10%)" }}
            >
              <Link to="/" className={`flex flex-column justify-center items-center ${path === "/" && "blue"}`}>
                <FaHome />
                <p className="p-0 m-0 text-xsm">home</p>
              </Link>
              {/* <Link to="/portfolio" className={`flex flex-column justify-center items-center ${path === "/portfolio" && "blue"}`}>

                <BsBookmarksFill />
                <p className="p-0 m-0 text-xsm">Portfolio</p>
              </Link> */}
              {/* <Link to="/" className={`  ${path === "/services" && "blue"} flex flex-column justify-center items-center`}>

                <MdHandyman />
                <p className="p-0 m-0 text-xsm">Services</p>
              </Link> */}
              <HashLink to={`#contact`} smooth={true} className={`flex flex-column justify-center items-center ${path === `${path}#contact` && "blue"}`}>

                <RiContactsFill />
                <p className="p-0 m-0 text-xsm">Contact Us</p>
              </HashLink>
            </div>
          </div>
        </>}
      </div>
    </>
  );
}
